/*  import style sheet */
@import "animal.css";
@import "button.css";
@import "contact.css";
@import "content.css";
@import "info.css";
@import "inputs.css";
@import "modal.css";
@import "navigation.css";

:root {
  --main-red-color: hsl(358, 84%, 52%);
  --main-blue-color: hsl(226, 45%, 45%);

  --red-color: hsla(0, 100%, 60%, 0.75);
  --green-color: hsla(122, 40%, 50%, 0.75);
  --blue-color: hsla(215, 80%, 40%, 0.75);
  --white-color: hsl(0, 0%, 100%);
  --black-color: hsla(0, 0%, 0%, 0.75);

  --products-width: 300px;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: Verdana, Geneva, Tahoma, sans-serif, fontawesome;
}

html {
  height: -webkit-fill-available;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

.app {
  position: relative;
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  transition: all ease-in-out;
}
