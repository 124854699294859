.button {
  width: 100%;
  border: solid 2px transparent;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  color: white;
  font-weight: 500;
}

.button_disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: var(--red-color);
}

.button_enabled {
  cursor: pointer;
  background-color: var(--green-color);
}

.button:hover,
.button:focus {
  border: solid 2px var(--orange-color-a);
  cursor: pointer;
}

@media (max-width: 600px) {
  .button {
    font-size: 1.25rem;
  }
}
