.navigation_container {
  background-color: var(--white-color);
  position: sticky;
  min-height: 10vh;
  top: 0;
  z-index: 10;
}

.navigation {
  background-color: var(--white-color);
}

.navigation_desktop {
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  font-size: 1.5rem;
}

.link {
  padding: 0.5rem 0;
}

.link a {
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.5s ease-in-out;
}

.link:nth-child(odd) a {
  color: var(--main-blue-color);
}

.link:nth-child(even) a {
  color: var(--main-red-color);
}

.link {
  transform-origin: top;
  transition: all 0.5s ease-in-out;
}

.link:hover {
  scale: 1.2;
}

.link:nth-child(odd):hover a {
  color: var(--main-red-color);
}

.link:nth-child(even):hover a {
  color: var(--main-blue-color);
}

/* add image to home link */
.home_link {
  color: transparent;
  background: url("./../../public/image/tika_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 120px;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.home_link:hover {
  background: url("./../../public/image/tika_logo_one.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.inner_link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner_link a {
  text-decoration: none;
  color: var(--main-blue-color);
}

/* mobile style */
.drop-down {
  position: absolute;
  top: 5dvh;
  right: 0;
  transform-origin: top;
  transition: height var(--speed) ease;
}

.drop-down .drop-down-items {
  border-bottom-left-radius: 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  gap: 0.5rem;
}

.drop-down .drop-down-items div {
  background-color: var(--white-color);
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.drop-down .drop-down-items div:nth-child(even) {
  border: solid 2px var(--main-red-color);
}

.drop-down .drop-down-items div:nth-child(odd) {
  border: solid 2px var(--main-blue-color);
}

.drop-down .drop-down-items div:nth-child(even) a {
  color: var(--main-blue-color);
}

.drop-down .drop-down-items div:nth-child(odd) a {
  color: var(--main-red-color);
}

@media (max-width: 475px) {
  .navigation_container {
    min-height: 5dvh;
  }
  .navigation_desktop {
    display: none;
  }

  .navigation_mobile {
    display: none;
  }

  .home_link {
    width: 80px;
  }

  .navigation_icon {
    display: block;
  }

  .drop-down-items .link {
    padding: 0;
  }

  .drop-down-items .link a {
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
}
