.container_contact {
  position: relative;
  width: 80%;
}

.contact_row {
  color: var(--main-blue-color);
}

.contact_row i {
  width: 2rem;
  padding: 0.5rem;
}

.contact_row span {
  padding: 0.5rem;
  font-weight: 600;
}

.contact_textarea {
  color: var(--main-blue-color);
}

.contact_textarea div i,
.contact_textarea div label {
  padding: 0.5rem;
}

.contact_textarea textarea {
  border: solid 1px var(--main-blue-color);
  border-bottom: solid 2px var(--main-blue-color);
  width: 100%;
  font-size: 1rem;
  color: var(--main-blue-color);
  padding: 0.5rem;
}
