.form {
  position: relative;
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 2rem;
}

.naroci_row,
.container_storitev {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: solid 2px var(--main-blue-color);
  margin: 30px 0;
}

.container_storitev {
  position: relative;
}

.container_storitev select {
  font-size: 1rem;
}

.naroci_row label,
.container_storitev label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 1.2rem;
  color: var(--main-blue-color);
  pointer-events: none;
  transition: 0.5s;
}

.naroci_row input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  color: var(--main-blue-color);
  font-weight: 600;
  padding: 0 35px 0 15px;
}

.naroci_row .icon,
.container_storitev i {
  position: absolute;
  right: 8px;
  color: var(--main-blue-color);
  line-height: 50px;
}

.naroci_row input:focus ~ label,
.naroci_row input:valid ~ label {
  color: var(--main-blue-color);
  top: -10px;
}

.storitev_select {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.container_animal select,
.storitev_select select {
  width: fit-content;
  height: 48px;
  border: none;
  outline: none;
  color: var(--main-blue-color);
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
}

.storitev_select select {
  margin-right: 35px;
}

/* input error show */
.show_error {
  color: var(--red-color) !important;
  transition: all 0.5s ease-in-out;
}

/* input error hide */
.hide_error {
  color: var(--main-blue-color);
  transition: all 0.5s ease-in-out;
}

@media (max-width: 475px) {
  .form {
    position: relative;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  } 
}