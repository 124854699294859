.content {
  width: 100%;
  z-index: -10;

  display: flex;
  flex-direction: column;
}

.content section {
  min-height: 89vh;
  scroll-snap-align: start;
  scroll-margin-top: 11vh;
}

.content .row {
  display: flex;
}

.content .column_left {
  width: 50vw;
}

.content .column_right {
  width: 50vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content .text {
  color: var(--main-blue-color);
  text-align: center;
  line-height: 1.2rem;
  padding: 1rem;
}

.content .text div {
  text-align: left;
}

@media (max-width: 475px) {
  .content section {
    min-height: 95dvh;
    scroll-snap-align: start;
    scroll-margin-top: 5dvh;
  }

  .content .row {
    display: flex;
    flex-direction: column;
  }

  .content .column_left {
    width: 100vw;
  }

  .content .column_right {
    width: 100vw;
  }
}
