.info {
  position: relative;
  background-color: var(--white-color);
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info div {
  display: flex;
  align-items: center;
}

.info div:nth-child(2) i,
.info div:nth-child(2) a {
  color: var(--main-blue-color);
}

.info div:nth-child(3) i,
.info div:nth-child(3) a {
  color: var(--main-red-color);
}

.info div i {
  font-size: 2rem;
}

.info div a {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  display: inline-block;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

.info div a span {
  display: inline-block;
}

.info div a {
  text-decoration: none;
}

.info .navigation_mobile {
  display: none;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button svg {
  fill: var(--main-red-color);
  width: 2rem;
  height: 2rem;
}

.info_links {
  transform-origin: top;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-size: 2rem;
  padding: 0.5rem 1rem;
}

.info_links div:nth-child(odd) a {
  color: var(--main-red-color);
}

.info_links div:nth-child(even) a {
  color: var(--main-blue-color);
}

.info_links div a i {
  transform-origin: top;
  transition: all 0.5s ease-in-out;
}

.info_links div a:hover i {
  transform: scale(1.25);
}

@media (max-width: 475px) {
  .info div i {
    font-size: 1rem;
  }

  .info div span,
  .info div a {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .info div a span {
    display: none;
  }

  .info .navigation_mobile {
    display: block;
  }

  .row.info_links {
    flex-direction: row;
  }

  .home {
    display: flex;
    flex-direction: column-reverse;
  }
}
