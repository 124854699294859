.container {
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2rem;
}

/* page content div */
.content .header {
  color: var(--main-blue-color);
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem;
  text-align: center;
}
