.modal {
  position: absolute;
  border-radius: 20px;
  background-color: var(--white-color);
  transform: translateY(-50%);
  transform: translateX(-50%);
  min-width: 50%;
  z-index: 10;
}

.modal_show {
  animation-name: modal_show;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes modal_show {
  0% {
    top: 40%;
    left: -200%;
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    top: 40%;
    left: 50%;
    opacity: 1;
  }
}

.modal_hide {
  animation-name: modal_hide;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes modal_hide {
  0% {
    top: 40%;
    left: 50%;
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    top: 40%;
    left: -200%;
    opacity: 0;
  }
}

.modal-title {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border: solid 2px var(--green-color);
  background-color: var(--white-color);
  color: var(--main-blue-color);
  padding: 10px 20px;
  text-align: center;
}

.modal-title i {
  border-top-right-radius: 18px;
  position: fixed;
  padding: 10px;
  cursor: pointer;
  top: 0;
  right: 0;
  color: var(--blue-color-a);
}

.modal-content {
  padding: 20px;
  border-left: solid 2px var(--green-color);
  border-right: solid 2px var(--green-color);
  background-color: var(--white-color);
  color: var(--main-blue-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
}

.modal-footer {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  border: solid 2px var(--green-color);
  background-color: var(--white-color);
  color: var(--main-blue-color);
  padding: 10px 20px;
  text-align: center;
}

@media (max-width: 600px) {
  .modal {
    min-width: 80%;
  }
}
