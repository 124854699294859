.container_animal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  border-bottom: solid 2px var(--main-blue-color);
  color: var(--main-blue-color);
  margin: 30px 0;
}

.container_animal i {
  font-size: 3rem;
  margin-bottom: 1px;
  color: var(--main-blue-color);
}

.container_animal select {
  font-size: 1rem;
  margin-bottom: 2px;
}

@media (max-width: 600px) {
  .container_animal i {
    font-size: 2.5rem;
  }
}
